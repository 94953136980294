interface ChevronDownIconProps {
  className?: string;
}

export const ChevronDownIcon = ({ className }: ChevronDownIconProps) => {
  return (
    <svg
      className={className}
      width="15"
      height="8"
      viewBox="0 0 15 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.98171 7.74398L14.3853 1.51468C14.7362 1.15205 14.7362 0.597369 14.3853 0.256015C14.0344 -0.0853384 13.4642 -0.0853385 13.0914 0.256015L7.32377 5.86669L1.55615 0.256015C1.20525 -0.085339 0.613184 -0.085339 0.262281 0.256015C-0.0886202 0.597368 -0.0886202 1.15205 0.262281 1.51468L6.68781 7.74398C7.03871 8.08534 7.60891 8.08534 7.98168 7.74398L7.98171 7.74398Z"
        fill="white"
      />
    </svg>
  );
};
