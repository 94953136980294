import { InMemoryCache } from "@apollo/client/cache";
import { ApolloClient, split } from "@apollo/client/core/index.js";
import { HttpLink } from "@apollo/client/link/http";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { ApolloProvider } from "@apollo/client/react/context/ApolloProvider";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";

interface GraphQLProviderProps {
  children: React.ReactNode;
  graphqlUrl?: string;
}

export function GraphQLProvider({
  children,
  graphqlUrl,
}: GraphQLProviderProps) {
  if (!graphqlUrl) {
    return <>{children}</>;
  }

  const wsLink = new GraphQLWsLink(
    createClient({
      url: new URL(graphqlUrl).toString().replace(/^http/, "ws"),
    }),
  );

  const httpLink = new HttpLink({
    uri: String(graphqlUrl),
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink,
  );

  const apolloClient = new ApolloClient({
    ssrMode: true,
    link: splitLink,
    cache: new InMemoryCache(),
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
}
