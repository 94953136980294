import clsx from "clsx";
import { useToaster } from "react-hot-toast/headless";

export default function Toaster({ hasPlaybar }: { hasPlaybar: boolean }) {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause, calculateOffset, updateHeight } = handlers;

  return (
    <div
      onMouseEnter={startPause}
      onMouseLeave={endPause}
      className={clsx("fixed right-8 z-[60] w-full sm:right-12", {
        "bottom-8": !hasPlaybar,
        "bottom-40": hasPlaybar,
      })}
    >
      {toasts.map((t) => {
        const offset = calculateOffset(t, {
          reverseOrder: false,
          gutter: 10,
        });
        const ref = (el: HTMLDivElement) => {
          if (el && typeof t.height !== "number") {
            const height = el.getBoundingClientRect().height;
            updateHeight(t.id, height);
          }
        };

        return (
          <div
            key={t.id}
            ref={ref}
            {...t.ariaProps}
            className={clsx(
              "rainbow-diagonal absolute right-0 w-full max-w-md rounded-2xl p-0.5 text-xl font-bold transition-all [bottom:calc(var(--offset)*1px)] [box-shadow:0_4px_4px_rgba(0,0,0,0.25)]",
              {
                "animate-slide-in": t.visible,
                "animate-slide-out": !t.visible,
              },
            )}
            style={
              {
                "--offset": offset,
              } as React.CSSProperties
            }
          >
            <div className="flex items-center rounded-2xl bg-black/90 px-8 py-6">
              {t.type === "success" ? (
                <svg
                  width="26"
                  height="26"
                  fill="none"
                  viewBox="0 0 31 31"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-4 flex-shrink-0"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3"
                    d="m9.425 15.5 4.05 4.05 8.1-8.1M29 15.5C29 22.956 22.956 29 15.5 29S2 22.956 2 15.5 8.044 2 15.5 2 29 8.044 29 15.5Z"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  width="26"
                  height="26"
                  className="mr-4 flex-shrink-0"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z"
                  />
                </svg>
              )}
              <>{String(t.message)}</>
            </div>
          </div>
        );
      })}
    </div>
  );
}
