import { ChevronDownIcon } from "./icon/ChevronDownIcon";
import { useClose } from "@headlessui/react";
import clsx from "clsx";
import { useEffect } from "react";

import { useFriendsSidebar } from "~/providers/FriendsContext";

interface UserBadgeProps {
  name: string;
  status?: string;
  className?: string;
  src?: string | null;
  isDropdown?: boolean;
  isDropdownOpen?: boolean;
  setIsDropdownOpen?: (value: boolean) => void;
}

export default function UserBadge({
  name,
  src,
  status,
  isDropdown,
  isDropdownOpen,
  setIsDropdownOpen,
  className,
}: UserBadgeProps) {
  const close = useClose();
  const { isVisible } = useFriendsSidebar();

  useEffect(() => {
    if (!isVisible) {
      close();
    }
  }, [isVisible, close]);

  return (
    <div
      onClick={() =>
        isDropdown && setIsDropdownOpen && setIsDropdownOpen(!isDropdownOpen)
      }
      className={clsx("relative flex h-full w-full items-center", className)}
    >
      <div className="relative h-[30px] w-[30px] rounded-full">
        {src ? (
          <img
            src={src}
            width="30"
            height="30"
            alt={name}
            loading="lazy"
            decoding="async"
            className="h-full w-full rounded-full"
          />
        ) : (
          <span className="flex aspect-square items-center justify-center rounded-full border border-white/20 bg-black font-bold">
            {name[0].toUpperCase()}
          </span>
        )}

        {status ? (
          <div
            className={clsx(
              "absolute -bottom-1 -right-1 h-4 w-4 rounded-full border-[2px] border-solid border-[#2b2b2b]",
              {
                "bg-white/60": status === "Offline",
                "bg-[#93E560]": status !== "Offline",
              },
            )}
          />
        ) : null}
      </div>

      <div className="ml-3 flex flex-col text-white">
        <p className="text-lg font-bold">{name}</p>
        {status ? (
          <p className="text-sm font-normal opacity-60">{status}</p>
        ) : null}
      </div>

      {isDropdown ? (
        <div className="ml-auto flex">
          <ChevronDownIcon />
        </div>
      ) : null}
    </div>
  );
}
