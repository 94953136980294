import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuSection,
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { type FriendshipDto } from "@hytopia.com/lib/dist/social/friends/getFriendships";
import { useRouteLoaderData } from "@remix-run/react";
import clsx from "clsx";
import { Fragment } from "react";
import toast from "react-hot-toast/headless";
import { useLocalStorage } from "usehooks-ts";

import UserBadge from "~/components/UserBadge";
import {
  ChevronDownIcon,
  MessageIcon,
  StarIcon,
} from "~/components/ui/icons/icon";
import { useFriendsSidebar } from "~/providers/FriendsContext";
import { type loader as rootLoader } from "~/root";
import { hytopiaClient } from "~/utils/hytopiaClient";

interface FriendsListSectionProps {
  text: string;
  friends: FriendshipDto[];
}

export default function FriendsListSection({
  text,
  friends,
}: FriendsListSectionProps) {
  const {
    joinLobby,
    blockFriend,
    unblockFriend,
    unfriendFriend,
    setIsChatOpen,
    favoriteFriend,
    unfavoriteFriend,
    setSelectedChatFriend,
  } = useFriendsSidebar();
  const rootLoaderData = useRouteLoaderData<typeof rootLoader>("root");
  const [collapsed, setCollapsed] = useLocalStorage(
    `${text}-list-collapsed`,
    false,
  );

  async function sendMessage(friendId: string, message: string) {
    if (!rootLoaderData?.currentUser) {
      return;
    }

    try {
      const sendMessageRequest = await hytopiaClient.social.chat.sendMessage(
        rootLoaderData.currentUser.accessToken,
        friendId,
        {
          message,
          uniquifier: window.crypto.randomUUID(),
        },
      );

      if (sendMessageRequest.error) {
        return toast.error(sendMessageRequest.error.message);
      }
      const friendship = friends.find((f) => f.id === friendId);
      if (friendship) {
        setSelectedChatFriend(friendship);
        setIsChatOpen(true);
      }
    } catch (error) {
      toast.error("Failed to send message. Please try again later.");
    }
  }

  return (
    <Disclosure
      as="div"
      defaultOpen={!collapsed}
      className="border-t border-white/10"
    >
      <DisclosureButton
        onClick={() => setCollapsed(!collapsed)}
        className={clsx(
          "mb-1 flex w-full items-center gap-x-1.5 px-8 pt-6 text-[12px] opacity-50",
          { "pb-2": !collapsed },
        )}
      >
        <p className="font-extrabold">{text}</p>
        <p className="font-normal">({friends.length})</p>

        <ChevronDownIcon
          width="10"
          height="6"
          className={clsx("ml-auto mr-2", {
            "rotate-180": !collapsed,
          })}
        />
      </DisclosureButton>
      <DisclosurePanel
        className={clsx(
          "flex flex-col px-8",
          { invisible: !friends.length },
          { visible: friends.length },
        )}
      >
        <>
          {friends.length ? (
            <ul className="flex flex-col">
              {friends.map((friendShip) => (
                <li key={friendShip.friend.id} className="group relative -mx-8">
                  <Popover>
                    <PopoverButton className="w-full cursor-pointer px-8 py-px text-left outline-none transition hover:bg-[#D9D9D9]/10">
                      <UserBadge
                        status={
                          friendShip.friend.isConnected
                            ? friendShip.friend.currentLobby
                              ? `Playing ${friendShip.friend.currentLobby.game.name}`
                              : "Online"
                            : "Offline"
                        }
                        name={friendShip.friend.username}
                        src={friendShip.friend.profilePictureURL}
                        className="px-0 py-2"
                      />
                    </PopoverButton>
                    <Transition
                      as={Fragment}
                      enter="transition duration-200 ease-out"
                      enterFrom="translate-x-2 opacity-0"
                      enterTo="translate-x-0 opacity-100"
                      leave="transition duration-200 ease-out"
                      leaveFrom="translate-x-0 opacity-100"
                      leaveTo="translate-x-2 opacity-0"
                    >
                      <PopoverPanel
                        anchor={{
                          gap: 12,
                          to: "left",
                          padding: 105,
                        }}
                        className="z-30"
                      >
                        <div className="w-72 overflow-hidden rounded-xl border border-white/10 bg-black/90 shadow-md">
                          <div className="relative">
                            {friendShip.friend.profilePictureURL ? (
                              <img
                                width="300"
                                height="300"
                                loading="lazy"
                                className="w-full"
                                alt="HYTOPIA user avatar"
                                src={friendShip.friend.profilePictureURL}
                              />
                            ) : (
                              <span className="flex aspect-square items-center justify-center bg-black text-7xl font-bold">
                                {friendShip.friend.username[0].toUpperCase()}
                              </span>
                            )}

                            <button
                              type="button"
                              onClick={() => {
                                setIsChatOpen(true);
                                setSelectedChatFriend(friendShip);
                              }}
                              className={clsx(
                                "group absolute left-4 top-4 flex aspect-square items-center justify-center rounded-xl border border-transparent bg-[#F1F1F1]/10 px-3 py-3 transition hover:border-white focus-visible:outline-0 focus-visible:ring-1 focus-visible:ring-white",
                              )}
                            >
                              <MessageIcon
                                width="18"
                                height="18"
                                className="stroke-2 text-white opacity-60 transition group-hover:opacity-100"
                              />
                            </button>

                            <button
                              type="button"
                              onClick={() => {
                                if (friendShip.isFavorited) {
                                  unfavoriteFriend(friendShip.id);
                                } else {
                                  favoriteFriend(friendShip.id);
                                }
                              }}
                              className={clsx(
                                "group absolute right-4 top-4 flex aspect-square items-center justify-center rounded-xl border border-transparent bg-[#F1F1F1]/10 px-3 py-3 transition hover:border-white focus-visible:outline-0 focus-visible:ring-1 focus-visible:ring-white",
                              )}
                            >
                              <StarIcon
                                width="20"
                                height="20"
                                className={clsx({
                                  "fill-[#F1C40F] text-[#F1C40F]":
                                    friendShip.isFavorited,
                                  "fill-transparent text-white/60":
                                    !friendShip.isFavorited,
                                })}
                              />
                            </button>
                          </div>
                          <div className="flex items-start justify-between gap-4 border-b border-white/10 px-5 py-4">
                            <div className="flex w-full items-start justify-between">
                              <div>
                                <p className="flex items-center gap-2 text-xl font-semibold text-white">
                                  {friendShip.friend.username}
                                  <span
                                    className={clsx("h-3 w-3 rounded-full", {
                                      "bg-white/60":
                                        !friendShip.friend.isConnected,
                                      "bg-[#93E560]":
                                        friendShip.friend.isConnected,
                                    })}
                                  ></span>
                                </p>
                                <p className="text-white/60">
                                  {friendShip.friend.isConnected
                                    ? friendShip.friend.currentLobby
                                      ? `Playing ${friendShip.friend.currentLobby.game.name}`
                                      : "Online"
                                    : "Offline"}
                                </p>
                              </div>
                              <Menu>
                                <MenuButton className="group cursor-pointer rounded-lg border border-white/10 px-2 py-2 text-white/60 transition hover:border-white/20 hover:text-white focus:shadow-none focus:outline-0 focus:ring-1 focus:ring-white focus:ring-offset-2 focus:ring-offset-black">
                                  <svg
                                    width="15"
                                    height="3"
                                    viewBox="0 0 20 5"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="w-6"
                                  >
                                    <path
                                      d="M2.38647 4.73864C1.72738 4.73864 1.16203 4.50852 0.690444 4.0483C0.224535 3.58239 -0.00557913 3.01705 0.000102683 2.35227C-0.00557913 1.70455 0.224535 1.15057 0.690444 0.690341C1.16203 0.230114 1.72738 0 2.38647 0C3.01147 0 3.5626 0.230114 4.03988 0.690341C4.52283 1.15057 4.76715 1.70455 4.77283 2.35227C4.76715 2.79545 4.65067 3.19886 4.4234 3.5625C4.20181 3.92045 3.91203 4.20739 3.55408 4.4233C3.19613 4.63352 2.80692 4.73864 2.38647 4.73864Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      d="M9.88647 4.73864C9.22738 4.73864 8.66203 4.50852 8.19044 4.0483C7.72453 3.58239 7.49442 3.01705 7.5001 2.35227C7.49442 1.70455 7.72453 1.15057 8.19044 0.690341C8.66203 0.230114 9.22738 0 9.88647 0C10.5115 0 11.0626 0.230114 11.5399 0.690341C12.0228 1.15057 12.2671 1.70455 12.2728 2.35227C12.2671 2.79545 12.1507 3.19886 11.9234 3.5625C11.7018 3.92045 11.412 4.20739 11.0541 4.4233C10.6961 4.63352 10.3069 4.73864 9.88647 4.73864Z"
                                      fill="currentColor"
                                    ></path>
                                    <path
                                      d="M17.3865 4.73864C16.7274 4.73864 16.162 4.50852 15.6904 4.0483C15.2245 3.58239 14.9944 3.01705 15.0001 2.35227C14.9944 1.70455 15.2245 1.15057 15.6904 0.690341C16.162 0.230114 16.7274 0 17.3865 0C18.0115 0 18.5626 0.230114 19.0399 0.690341C19.5228 1.15057 19.7671 1.70455 19.7728 2.35227C19.7671 2.79545 19.6507 3.19886 19.4234 3.5625C19.2018 3.92045 18.912 4.20739 18.5541 4.4233C18.1961 4.63352 17.8069 4.73864 17.3865 4.73864Z"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                </MenuButton>
                                <Transition
                                  enter="transition ease-out duration-75"
                                  enterFrom="opacity-0 scale-95"
                                  enterTo="opacity-100 scale-100"
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100 scale-100"
                                  leaveTo="opacity-0 scale-95"
                                >
                                  <MenuItems
                                    unmount={false}
                                    anchor="bottom end"
                                    className="z-30 mt-2 flex w-36 origin-top-right flex-col overflow-hidden rounded-lg border border-white/10 bg-neutral-800 shadow-lg will-change-transform focus:outline-0"
                                  >
                                    <MenuSection className="flex flex-col">
                                      {friendShip.friend.currentLobby ? (
                                        <>
                                          <MenuItem>
                                            <button
                                              type="button"
                                              onClick={() => {
                                                if (
                                                  !friendShip.friend
                                                    .currentLobby
                                                ) {
                                                  return;
                                                }

                                                joinLobby(
                                                  friendShip.friend.currentLobby
                                                    .id,
                                                );
                                              }}
                                              className="w-full px-3 py-2 text-right font-bold text-[#93E560] transition hover:bg-white/5 hover:text-white [&[data-headlessui-state^=active]]:bg-white/5 [&[data-headlessui-state^=active]]:text-white"
                                            >
                                              Join
                                            </button>
                                          </MenuItem>
                                        </>
                                      ) : null}
                                      <MenuItem>
                                        <button
                                          onClick={() => {
                                            if (friendShip.friend.isBlocked) {
                                              unblockFriend(
                                                friendShip.friend.id,
                                              );
                                            } else {
                                              blockFriend(friendShip.friend.id);
                                            }
                                          }}
                                          type="button"
                                          className="w-full px-3 py-2 text-right font-bold text-white/60 transition hover:bg-white/5 hover:text-white [&[data-headlessui-state^=active]]:bg-white/5 [&[data-headlessui-state^=active]]:text-white"
                                        >
                                          {friendShip.friend.isBlocked
                                            ? "Unblock"
                                            : "Block"}
                                        </button>
                                      </MenuItem>
                                      <MenuItem>
                                        <button
                                          type="button"
                                          onClick={() =>
                                            unfriendFriend(friendShip.friend)
                                          }
                                          className="w-full px-3 py-2 text-right font-bold text-white/60 transition hover:bg-white/5 hover:text-white [&[data-headlessui-state^=active]]:bg-white/5 [&[data-headlessui-state^=active]]:text-white"
                                        >
                                          Unfriend
                                        </button>
                                      </MenuItem>
                                      <input
                                        type="hidden"
                                        name="friendId"
                                        value={friendShip.friend.id}
                                      />
                                    </MenuSection>
                                  </MenuItems>
                                </Transition>
                              </Menu>
                            </div>
                          </div>
                          <div className="px-5 py-4">
                            <div className="grid after:invisible after:col-start-1 after:row-start-1 after:whitespace-pre-wrap after:px-5 after:py-3.5 after:[content:attr(data-replicated-value)_'']">
                              <textarea
                                rows={1}
                                onInput={(e) => {
                                  if (
                                    e.currentTarget.parentNode instanceof
                                    HTMLElement
                                  ) {
                                    e.currentTarget.parentNode.dataset.replicatedValue =
                                      e.currentTarget.value;
                                  }
                                }}
                                onKeyDown={async (e) => {
                                  if (e.key === "Enter" && !e.shiftKey) {
                                    e.preventDefault();

                                    const message =
                                      e.currentTarget.value.trim();
                                    e.currentTarget.value = "";

                                    await sendMessage(friendShip.id, message);
                                  }
                                }}
                                disabled={
                                  friendShip?.friend.isBlocked ||
                                  friendShip.friend.isBlockedByOther
                                }
                                placeholder={
                                  friendShip?.friend.isBlocked
                                    ? "You blocked this user."
                                    : friendShip.friend.isBlockedByOther
                                      ? "This user blocked you."
                                      : "Quick message..."
                                }
                                className="col-start-1 row-start-1 w-full resize-none overflow-hidden rounded-xl border border-transparent bg-neutral-800 px-4 py-3.5 shadow-md outline outline-offset-1 outline-transparent transition-all placeholder:font-bold placeholder:text-neutral-500 focus:border-white focus:shadow-none disabled:cursor-not-allowed disabled:opacity-60"
                              />
                            </div>
                          </div>
                        </div>
                      </PopoverPanel>
                    </Transition>
                    <button
                      type="button"
                      onClick={() => {
                        if (friendShip.isFavorited) {
                          unfavoriteFriend(friendShip.id);
                        } else {
                          favoriteFriend(friendShip.id);
                        }
                      }}
                      className="absolute right-8 top-4 items-center opacity-0 transition-opacity group-hover:opacity-100"
                    >
                      <StarIcon
                        width="20"
                        height="20"
                        className={clsx({
                          "fill-[#F1C40F] text-[#F1C40F]":
                            friendShip.isFavorited,
                          "fill-transparent text-white/60":
                            !friendShip.isFavorited,
                        })}
                      />
                    </button>
                  </Popover>
                </li>
              ))}
            </ul>
          ) : null}
        </>
      </DisclosurePanel>
    </Disclosure>
  );
}
