import {
  CloseButton,
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { type MessageReportCategory } from "@hytopia.com/lib/dist/social/chat/reportMessage";
import { useState } from "react";
import toast from "react-hot-toast/headless";

import { CloseIcon, DownArrowIcon } from "~/components/ui/icons/icon";
import { useFriendsSidebar } from "~/providers/FriendsContext";

export interface ReportMessageModalProps {
  isOpen: boolean;
  messageId: string;
  setIsOpen: (isOpen: boolean) => void;
}

const ReportReasons: Record<MessageReportCategory, string> = {
  MESSAGE_SPAMMING: "Spamming",
  MESSAGE_ASKING_FOR_PID: "Asking for personal information",
  MESSAGE_BAN_EVASION: "Ban evasion",
  MESSAGE_CHEATING_ENCOURAGEMENT: "Cheating encouragement",
  MESSAGE_COERCION: "Coercion",
  MESSAGE_EXPLIT_OR_GRAPHIC_CONTENT: "Explicit or graphic content",
  MESSAGE_FINANCIAL_SCHEMES: "Financial schemes",
  MESSAGE_GAMBLING_PROMOTION: "Gambling promotion",
  MESSAGE_GASLIGHTING: "Gaslighting",
  MESSAGE_HATE_SPEECH: "Hate speech",
  MESSAGE_ILLEGAL_ACTIVITY: "Illegal activity",
  MESSAGE_ILLEGAL_DRUG_ENCOURAGEMENT: "Illegal drug encouragement",
  MESSAGE_MALWARE_SHARING: "Malware sharing",
  MESSAGE_MISINFORMATION: "Misinformation",
  MESSAGE_OFFENSIVE_LANGUAGE: "Offensive language",
  MESSAGE_PIRACY: "Piracy",
  MESSAGE_POSTING_PID: "Posting personal information",
  MESSAGE_SCAM_OR_PHISHING_LINK: "Scam or phishing links",
  MESSAGE_SELF_HARM_ENCOURAGEMENT: "Self-harm encouragement",
  MESSAGE_SENDER_IS_UNDERAGE: "Sender is underage",
  MESSAGE_SEXUAL_EXPLOITATION: "Sexual exploitation",
  MESSAGE_STAFF_IMPERSONATION: "Staff impersonation",
  MESSAGE_TERRORISM: "Terrorism",
  MESSAGE_VIOLENCE_PROMOTION: "Violence promotion",
  MESSAGE_UNSOLICITED_SEXUAL_CONTENT: "Unsolicited sexual content",
};

export default function ReportMessageModal({
  isOpen,
  setIsOpen,
  messageId,
}: ReportMessageModalProps) {
  const { reportMessage } = useFriendsSidebar();
  const [selectedReason, setSelectedReason] = useState<MessageReportCategory>();

  return (
    <Dialog
      open={isOpen}
      className="relative z-50"
      onClose={() => {
        setIsOpen(false);
      }}
    >
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-black/90 duration-300 ease-out data-[closed]:opacity-0 data-[closed]:duration-100 data-[closed]:ease-in"
      />

      <div className="fixed inset-0 flex items-center justify-center p-8">
        <DialogPanel
          transition
          className="w-full max-w-xl rounded-3xl border border-white/10 bg-white/10 backdrop-blur-lg duration-300 ease-out will-change-transform data-[closed]:scale-95 data-[closed]:opacity-0 data-[closed]:duration-100 data-[closed]:ease-in"
        >
          <div className="px-8 py-6">
            <div className="flex items-center justify-between">
              <DialogTitle className="text-xl font-bold text-neutral-100">
                Report this message
              </DialogTitle>
              <CloseButton className="rounded-lg border border-white/10 p-3 text-white hover:text-white">
                <CloseIcon className="h-3 w-3" />
              </CloseButton>
            </div>

            <div className="mt-4">
              <label className="mb-4 block text-lg font-semibold text-white">
                Why are you reporting this message?
              </label>

              <Listbox
                value={selectedReason}
                onChange={(reason) => {
                  setSelectedReason(reason);
                }}
              >
                <div className="relative">
                  <ListboxButton
                    className={`relative h-14 w-full cursor-pointer rounded-lg bg-white/10 pl-3 text-left text-white focus:outline-none`}
                  >
                    <span className="block truncate text-lg font-bold">
                      {selectedReason
                        ? ReportReasons[selectedReason]
                        : "Select a reason"}
                    </span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                      <DownArrowIcon
                        className="h-3 w-3 text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </ListboxButton>
                  <ListboxOptions className="absolute z-[60] mt-1 max-h-60 w-full overflow-auto rounded-lg border border-white/10 bg-[#272727] shadow-lg focus:outline-none">
                    {Object.keys(ReportReasons).map((reason) => (
                      <ListboxOption
                        key={reason}
                        value={reason}
                        className="relative cursor-pointer select-none py-[10px] pl-[18px] pr-4 text-white hover:bg-white/5"
                      >
                        <span className="block truncate text-lg font-bold">
                          {ReportReasons[reason as MessageReportCategory]}
                        </span>
                      </ListboxOption>
                    ))}
                  </ListboxOptions>
                </div>
              </Listbox>
            </div>
          </div>
          <div className="rounded-b-2xl bg-[#3d3d3d] px-8 py-5">
            <button
              type="button"
              onClick={async () => {
                try {
                  const result = await reportMessage(
                    messageId,
                    selectedReason as MessageReportCategory,
                  );

                  if (result) {
                    setIsOpen(false);
                    toast.success("Message reported successfully.");
                  }
                } catch (error) {
                  console.error(error);
                  toast.error("Something went wrong. Please try again later.");
                }
              }}
              className="h-14 w-full rounded-lg bg-white text-lg font-semibold text-black transition hover:bg-white/70"
            >
              Report
            </button>
          </div>
        </DialogPanel>
      </div>
    </Dialog>
  );
}
