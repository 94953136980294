import { Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import {
  getAccessToken,
  useLoginWithEmail,
  useLogout,
} from "@privy-io/react-auth";
import {
  Form,
  useFetcher,
  useFetchers,
  useLocation,
  useRouteLoaderData,
} from "@remix-run/react";
import clsx from "clsx";
import type React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import ConfettiExplosion from "react-confetti-explosion";
import toast from "react-hot-toast/headless";
import OTPInput from "react-otp-input";
import { useDebounceValue } from "usehooks-ts";

import { StopSignIcon } from "~/components/ui/icons/icon";
import { type loader as rootLoader } from "~/root";
import { hytopiaClient } from "~/utils/hytopiaClient";
import { startViewTransition } from "~/utils/transitions.client";

type Step =
  | "email"
  | "otp"
  | "username"
  | "age"
  | "terms"
  | "denied"
  | "letsgo"
  | "loading"
  | undefined;

const LoginModal = () => {
  const fetcher = useFetcher();
  const fetchers = useFetchers();
  const { logout } = useLogout();
  const location = useLocation();
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const termsRef = useRef<HTMLDivElement>(null);
  const [birthYear, setBirthYear] = useState("");
  const [birthMonth, setBirthMonth] = useState("");
  const [step, setStep] = useState<Step>(undefined);
  const [usernameError, setUsernameError] = useState("");
  const usernameInputRef = useRef<HTMLInputElement>(null);
  const [passedAgeGate, setPassedAgeGate] = useState(false);
  const [debouncedUsername] = useDebounceValue(username, 250);
  const [canAcceptTerms, setCanAcceptTerms] = useState(false);
  const { sendCode, loginWithCode, state } = useLoginWithEmail();
  const [usernameAvailable, setUsernameAvailable] = useState(false);
  const rootLoaderData = useRouteLoaderData<typeof rootLoader>("root");
  const [preRegistrationUsername, setPreRegistrationUsername] = useState("");
  const emailInputRef = useRef<HTMLInputElement>(null);

  const validateUsername = useCallback(
    async (value: string) => {
      if (!value || value.length < 4) {
        setUsernameError("");
        setUsernameAvailable(false);
        return;
      }

      setUsernameError("");
      setUsernameAvailable(false);

      if (value === preRegistrationUsername) {
        setUsernameAvailable(true);
        return;
      }

      try {
        const checkUsernameResponse =
          await hytopiaClient.auth.checkUsername(value);

        if (checkUsernameResponse.error) {
          setUsernameAvailable(false);
          return setUsernameError(checkUsernameResponse.error.message);
        }

        if (!checkUsernameResponse.available || !checkUsernameResponse.valid) {
          setUsernameAvailable(false);
          return setUsernameError("Username is not available or not valid");
        }

        setUsernameAvailable(true);
      } catch (error) {
        console.error(error);
        setUsernameAvailable(false);
        toast.error("Something went wrong. Please try again.");
      }
    },
    [preRegistrationUsername],
  );

  const handleUsernameValidation = async (
    e: React.FormEvent<HTMLFormElement>,
  ) => {
    e.preventDefault();

    if (usernameError) {
      return;
    }

    startViewTransition(() => setStep("age"));
  };

  const handleAgeValidation = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const birthDate = new Date(
        `${birthYear}-${birthMonth}-${birthDay}`,
      ).getTime();
      const currentDate = new Date().getTime();
      const age = Math.floor((currentDate - birthDate) / 31556952000);

      setPassedAgeGate(age >= 13);
      startViewTransition(() => setStep("terms"));
    } catch (error) {
      console.error(error);

      toast.error("Something went wrong. Please try again.");
    }
  };

  const handleScroll = () => {
    if (!termsRef.current) {
      return;
    }

    const { scrollTop, scrollHeight, clientHeight } = termsRef.current;

    setCanAcceptTerms(scrollTop + clientHeight >= scrollHeight - 20);
  };

  const handleSignUp = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      const privyToken = await getAccessToken();

      if (!privyToken) {
        return toast.error("Privy token not found.");
      }

      const signupResponse = await hytopiaClient.auth.signupWithPrivy({
        username,
        privyToken,
        passedAgeGate,
      });

      if (signupResponse.error) {
        return toast.error(signupResponse.error.message);
      }

      fetcher.submit(null, {
        method: "POST",
        preventScrollReset: true,
        action: `/auth/?redirectTo=${location.pathname}`,
      });

      startViewTransition(() => setStep("letsgo"));
    } catch (error) {
      console.error(error);

      toast.error("Something went wrong. Please try again.");
    }
  };

  const handlePreRegistrationUsername = async () => {
    try {
      const privyToken = await getAccessToken();

      if (!privyToken) {
        return toast.error("Privy token not found.");
      }

      const preRegistrationRequest =
        await hytopiaClient.auth.getPreregistrationData(privyToken);

      if (preRegistrationRequest.error) {
        return toast.error(preRegistrationRequest.error.message);
      }

      if (preRegistrationRequest.username) {
        setUsername(preRegistrationRequest.username);
        setPreRegistrationUsername(preRegistrationRequest.username);
      }

      startViewTransition(() => setStep("username"));
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please try again.");
    }
  };

  const handleEmailValidation = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await sendCode({ email });
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please try again.");
    }
  };

  const handleOtpValidation = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await loginWithCode({ code: otp });
    } catch (error) {
      console.error(error);
      toast.error("Invalid verification code. Please try again.");
    }
  };

  const renderContent = () => {
    switch (step) {
      case "loading":
        return (
          <div className="mx-auto w-full max-w-lg overflow-hidden rounded-2xl bg-[#1F1F1E] shadow-xl [view-transition-name:login-modal]">
            <div className="flex flex-col items-center justify-center p-8">
              <img
                width="213"
                height="31"
                loading="lazy"
                decoding="async"
                src="/img/logo.svg"
                alt="HYTOPIA Logo"
                className="mb-8"
              />
              <div className="h-12 w-12 animate-spin rounded-full border-4 border-[#42468F] border-t-[#666DFF]"></div>
              <p className="mt-6 text-xl font-medium text-white">
                Fetching account data...
              </p>
            </div>
          </div>
        );

      case "email":
        return (
          <Form
            onSubmit={handleEmailValidation}
            className="mx-auto w-full max-w-lg overflow-hidden rounded-2xl bg-[#1F1F1E] shadow-xl [view-transition-name:login-modal]"
          >
            <div className="p-8">
              <p className="mb-1 mt-4 text-center text-lg font-medium text-neutral-100">
                Welcome To
              </p>
              <img
                width="213"
                height="31"
                loading="lazy"
                decoding="async"
                src="/img/logo.svg"
                alt="HYTOPIA Logo"
                className="mx-auto mb-8"
              />
              <label
                htmlFor="email"
                className="mb-1 block text-2xl font-bold text-neutral-100"
              >
                Enter your email
              </label>
              <p className="mb-3 text-lg text-white/60">
                We will send you a verification code to this email.
              </p>
              <input
                required
                type="email"
                id="email"
                value={email}
                autoComplete="off"
                ref={emailInputRef}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
                className="peer w-full rounded-2xl bg-[#3E3D3D] px-6 py-5 text-[16px] font-bold leading-tight text-white outline-none placeholder:font-normal placeholder:text-white focus:ring-2 focus:ring-white/50"
              />
              {state.status === "error" ? (
                <span className="mt-4 block leading-normal text-red-500">
                  {state.error?.message}.
                </span>
              ) : null}
            </div>

            <div className="bg-[#3d3d3d] p-8">
              <button
                type="submit"
                disabled={!email || state.status === "sending-code"}
                className="w-full rounded-xl border border-white bg-white px-10 py-5 text-2xl font-bold text-[#151515] shadow-md transition hover:border-white/80 hover:bg-white/80 active:translate-y-1 active:shadow-none disabled:cursor-not-allowed disabled:opacity-50"
              >
                {state.status === "sending-code"
                  ? "Sending Code..."
                  : "Save Email"}
              </button>
            </div>
          </Form>
        );

      case "otp":
        return (
          <Form
            onSubmit={handleOtpValidation}
            className="mx-auto w-full max-w-lg overflow-hidden rounded-2xl bg-[#1F1F1E] shadow-xl [view-transition-name:login-modal]"
          >
            <div className="p-8">
              <p className="mb-1 mt-4 text-center text-lg font-medium text-neutral-100">
                Welcome To
              </p>
              <img
                width="213"
                height="31"
                loading="lazy"
                decoding="async"
                src="/img/logo.svg"
                alt="HYTOPIA Logo"
                className="mx-auto mb-8"
              />
              <label
                htmlFor="otp"
                className="mb-1 block text-2xl font-bold text-neutral-100"
              >
                Enter verification code
              </label>
              <p className="mb-3 text-lg text-white/60">
                We sent a verification code to {email}
              </p>
              <OTPInput
                value={otp}
                numInputs={6}
                onChange={setOtp}
                shouldAutoFocus
                containerStyle="gap-2"
                renderInput={(props) => (
                  <input
                    {...props}
                    maxLength={1}
                    inputMode="text"
                    className="flex-1 rounded-xl border border-white/10 bg-[#302F30] px-3 py-6 text-xl text-white shadow-md transition focus:border-transparent focus:outline-none focus:ring-2 focus:ring-white"
                  />
                )}
              />
              <p className="mt-4 flex items-center gap-2 text-white/60">
                <span>Didn&apos;t get an email?</span>
                <button
                  type="button"
                  onClick={() => sendCode({ email })}
                  disabled={state.status === "sending-code"}
                  className="font-bold text-white hover:text-white/80"
                >
                  {state.status === "sending-code"
                    ? "Sending..."
                    : "Resend code"}
                </button>
              </p>
              {state.status === "error" ? (
                <span className="mt-4 block leading-normal text-red-500">
                  {state.error?.message}.
                </span>
              ) : null}
            </div>

            <div className="bg-[#3d3d3d] p-8">
              <button
                type="submit"
                disabled={
                  otp.length !== 6 || state.status === "submitting-code"
                }
                className="w-full rounded-xl border border-white bg-white px-10 py-5 text-2xl font-bold text-[#151515] shadow-md transition hover:border-white/80 hover:bg-white/80 active:translate-y-1 active:shadow-none disabled:cursor-not-allowed disabled:opacity-50"
              >
                {state.status === "submitting-code"
                  ? "Verifying..."
                  : "Verify Code"}
              </button>
              <button
                type="button"
                onClick={() => {
                  setOtp("");
                  startViewTransition(() => setStep("email"));
                }}
                className="mt-4 w-full px-5 py-2 text-center text-lg font-bold uppercase text-white/60 transition hover:text-white disabled:cursor-not-allowed"
              >
                Change Email
              </button>
            </div>
          </Form>
        );

      case "username":
        return (
          <Form
            onSubmit={handleUsernameValidation}
            className="mx-auto w-full max-w-lg overflow-hidden rounded-2xl bg-[#1F1F1E] shadow-xl [view-transition-name:login-modal]"
          >
            <div className="p-8">
              <p className="mb-1 mt-4 text-center text-lg font-medium text-neutral-100">
                Welcome To
              </p>
              <img
                width="213"
                height="31"
                loading="lazy"
                decoding="async"
                src="/img/logo.svg"
                alt="HYTOPIA Logo"
                className="mx-auto mb-8"
              />
              <label
                htmlFor="username"
                className="mb-3 block text-2xl font-bold text-neutral-100"
              >
                Create a username
              </label>
              {preRegistrationUsername ? (
                <p className="mb-3 text-lg/normal text-white/60">
                  You have pre-registered the username &quot;
                  <strong>{preRegistrationUsername}</strong>&quot;. You can use
                  it to sign up or choose a different one.
                </p>
              ) : null}
              <input
                required
                type="text"
                id="username"
                minLength={4}
                maxLength={20}
                value={username}
                autoComplete="off"
                ref={usernameInputRef}
                placeholder="Username"
                pattern="^[a-zA-Z0-9]+$"
                title="Username can only contain letters and numbers"
                onChange={(e) => setUsername(e.target.value)}
                className="peer w-full rounded-2xl bg-[#3E3D3D] px-6 py-5 text-[16px] font-bold leading-tight text-white outline-none placeholder:font-normal placeholder:text-white focus:ring-2 focus:ring-white/50"
              />
              {usernameError ? (
                <span className="mt-4 block leading-normal text-red-500">
                  {usernameError}.
                </span>
              ) : usernameAvailable && username.length >= 4 ? (
                <span className="rainbow-diagonal mt-4 inline-block bg-clip-text font-bold [-webkit-text-fill-color:transparent] [text-fill-color:transparent]">
                  ✔️ Available
                </span>
              ) : null}
            </div>

            <div className="bg-[#3d3d3d] p-8">
              <button
                type="submit"
                disabled={!username || !usernameAvailable}
                className="w-full rounded-xl border border-white bg-white px-10 py-5 text-2xl font-bold text-[#151515] shadow-md transition hover:border-white/80 hover:bg-white/80 active:translate-y-1 active:shadow-none disabled:cursor-not-allowed disabled:opacity-50"
              >
                Save Username
              </button>
            </div>
          </Form>
        );

      case "age":
        return (
          <Form
            onSubmit={handleAgeValidation}
            className="mx-auto w-full max-w-4xl overflow-hidden rounded-2xl bg-[#1F1F1E] shadow-xl [view-transition-name:login-modal]"
          >
            <div className="relative flex h-[350px] flex-col justify-center bg-[#1F1F1F]">
              <div className="absolute inset-0">
                <img
                  width="614"
                  height="697"
                  loading="lazy"
                  decoding="async"
                  alt="Background"
                  src="/img/agemodal.png"
                  className="h-[379px] w-full object-cover"
                />

                <div className="absolute left-0 top-0 z-0 h-[400px] w-full bg-gradient-to-b from-transparent to-[#1F1F1E]"></div>
              </div>

              <div className="absolute left-10 top-12 z-10">
                <img src="/img/logo.svg" alt="Logo" className="h-10 w-auto" />
              </div>
            </div>

            <div className="relative z-10 px-4 sm:px-8">
              <div className="text-left">
                <p className="mb-3 text-2xl font-bold text-white sm:text-[32px]">
                  Confirm your age
                </p>
                <p className="mb-6 ml-1 text-xl font-medium text-white/80 sm:text-xl">
                  To continue, please confirm your date of birth
                </p>
              </div>
              <div className="grid grid-cols-1 gap-3 px-2 sm:grid-cols-3 sm:gap-4">
                <select
                  value={birthMonth}
                  onChange={(e) => setBirthMonth(e.target.value)}
                  className="w-full rounded-[10px] bg-white/10 px-4 py-4 text-base font-bold text-white transition focus:outline-none focus:ring-2 focus:ring-white/50 sm:text-lg"
                >
                  <option value="">Month</option>
                  {Array.from({ length: 12 }, (_, i) => (
                    <option key={i} value={i + 1} className="text-black">
                      {new Date(2024, i).toLocaleString("default", {
                        month: "long",
                      })}
                    </option>
                  ))}
                </select>

                <select
                  value={birthDay}
                  onChange={(e) => setBirthDay(e.target.value)}
                  className="w-full rounded-[10px] bg-white/10 px-4 py-4 text-base font-bold text-white transition focus:outline-none focus:ring-2 focus:ring-white/50 sm:text-lg"
                >
                  <option value="">Day</option>
                  {Array.from({ length: 31 }, (_, i) => (
                    <option key={i} value={i + 1} className="text-black">
                      {i + 1}
                    </option>
                  ))}
                </select>

                <select
                  value={birthYear}
                  onChange={(e) => setBirthYear(e.target.value)}
                  className="w-full rounded-[10px] bg-white/10 px-4 py-4 text-base font-bold text-white transition focus:outline-none focus:ring-2 focus:ring-white/50 sm:text-lg"
                >
                  <option value="">Year</option>
                  {Array.from({ length: 100 }, (_, i) => {
                    const year = new Date().getFullYear() - i;
                    return (
                      <option key={year} value={year} className="text-black">
                        {year}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <div className="mt-8 bg-[#3d3d3d] px-4 py-6 sm:px-12 sm:py-8">
              <button
                type="submit"
                disabled={!birthMonth || !birthDay || !birthYear}
                className="w-full rounded-xl border border-white bg-white px-10 py-5 text-2xl font-bold text-[#151515] shadow-md transition hover:border-white/80 hover:bg-white/80 active:translate-y-1 active:shadow-none disabled:cursor-not-allowed disabled:opacity-50"
              >
                Confirm Age
              </button>
            </div>
          </Form>
        );

      case "terms":
        return (
          <Form
            onSubmit={handleSignUp}
            className="mx-auto w-full max-w-4xl overflow-hidden rounded-2xl bg-[#1F1F1E] shadow-xl [view-transition-name:login-modal]"
          >
            <div className="relative flex max-h-[511px] flex-col">
              <div className="px-12 py-6">
                <div className="mb-4 mt-4 border-b border-white/10 pb-8">
                  <img
                    width="187"
                    height="30"
                    loading="lazy"
                    decoding="async"
                    src="/img/logo.svg"
                    alt="HYTOPIA Logo"
                  />
                </div>
                <div className="mt-8">
                  <h2 className="mb-6 text-[32px] font-bold text-white">
                    Terms & Conditions
                  </h2>
                  <h3 className="ml-1 text-xl font-medium text-white">
                    To continue, accept our Terms & Conditions
                  </h3>
                </div>
              </div>
              <div
                ref={termsRef}
                onScroll={handleScroll}
                className="flex-1 overflow-y-auto px-12 [&::-webkit-scrollbar-thumb]:rounded-lg [&::-webkit-scrollbar-thumb]:bg-white/20 hover:[&::-webkit-scrollbar-thumb]:bg-white/25 [&::-webkit-scrollbar-track]:rounded-lg [&::-webkit-scrollbar-track]:bg-white/5 [&::-webkit-scrollbar]:w-2"
              >
                <div className="prose prose-xl pb-6 text-white/60 prose-headings:text-white">
                  <h2>HYTOPIA Alpha Test - Terms of Service</h2>
                  <p className="text-sm text-white/40">
                    Last Updated: February 16, 2025
                  </p>

                  <p>
                    Welcome to the HYTOPIA Alpha Test! By accessing or using our
                    platform, you agree to these Terms of Service. As this is an
                    early test phase, please understand that features may
                    change, and unexpected issues may arise.
                  </p>

                  <h3>1. Acceptance of Terms</h3>
                  <p>
                    By participating in the HYTOPIA Alpha Test, you acknowledge
                    that this is a pre-release version of the platform, and your
                    access is subject to these Terms. If you do not agree,
                    please do not use the platform.
                  </p>

                  <h3>2. User Conduct</h3>
                  <p>You agree to:</p>
                  <ul>
                    <li>
                      Respect other users and avoid any form of harassment,
                      cheating, or exploitative behavior.
                    </li>
                    <li>
                      Follow any rules or guidelines set by the HYTOPIA team.
                    </li>
                    <li>
                      Refrain from uploading or sharing illegal, offensive, or
                      harmful content.
                    </li>
                  </ul>

                  <h3>3. Account and Data</h3>
                  <ul>
                    <li>
                      You may be required to create an account to access the
                      platform.
                    </li>
                    <li>
                      We may collect and analyze gameplay data to improve the
                      platform.
                    </li>
                    <li>
                      Your progress, in-game items, or account status may be
                      reset or changed at any time.
                    </li>
                  </ul>

                  <h3>4. Alpha Test Disclaimer</h3>
                  <ul>
                    <li>
                      HYTOPIA is in an early development stage and may contain
                      bugs, glitches, or incomplete features.
                    </li>
                    <li>
                      The platform is provided &quot;as is,&quot; without any
                      guarantees of stability or performance.
                    </li>
                    <li>
                      The HYTOPIA team reserves the right to suspend or
                      terminate access at any time.
                    </li>
                  </ul>

                  <h3>5. Intellectual Property</h3>
                  <ul>
                    <li>
                      All game content, assets, and intellectual property belong
                      to the HYTOPIA team.
                    </li>
                    <li>
                      Users may not copy, distribute, or modify any aspect of
                      the platform without permission.
                    </li>
                  </ul>

                  <h3>6. Feedback and Confidentiality</h3>
                  <ul>
                    <li>
                      By participating, you agree to provide feedback that may
                      help improve the platform.
                    </li>
                    <li>
                      You may not publicly share details about unreleased
                      features or content without permission.
                    </li>
                  </ul>

                  <h3>7. Changes to Terms</h3>
                  <p>
                    These Terms may be updated as needed. Continued use of the
                    platform after changes means you accept the updated Terms.
                  </p>

                  <h3>8. Contact Us</h3>
                  <p>
                    For any questions or concerns regarding these Terms, please
                    reach out our team via one of our social medias found at{" "}
                    <a
                      href="https://hytopia.com/"
                      className="text-white hover:text-white/80"
                    >
                      https://hytopia.com/
                    </a>
                    .
                  </p>

                  <p className="mt-8 font-bold">
                    Thank you for being part of the HYTOPIA Alpha Test!
                  </p>
                </div>

                <div
                  className={clsx(
                    "pointer-events-none absolute bottom-0 left-0 right-0 h-36 select-none bg-gradient-to-b from-transparent to-[#1F1F1E] transition",
                    {
                      "opacity-0": canAcceptTerms,
                    },
                  )}
                ></div>
              </div>
            </div>
            <div className="flex w-full gap-6 bg-[#3d3d3d] px-12 py-8">
              <button
                type="button"
                onClick={() => startViewTransition(() => setStep("denied"))}
                className="basis-1/5 py-5 text-xl font-bold text-white/60 transition hover:text-white sm:text-2xl"
              >
                Deny
              </button>
              <button
                type="submit"
                disabled={!canAcceptTerms}
                className="w-full rounded-xl border border-white bg-white px-10 py-5 text-2xl font-bold text-[#151515] shadow-md transition hover:border-white/80 hover:bg-white/80 active:translate-y-1 active:shadow-none disabled:cursor-not-allowed disabled:opacity-50"
              >
                Accept & Sign Up
              </button>
            </div>
          </Form>
        );

      case "denied":
        return (
          <div className="mx-auto w-full max-w-md overflow-hidden rounded-2xl bg-[#1F1F1E] shadow-xl [view-transition-name:login-modal]">
            <div className="mt-4 p-8">
              <StopSignIcon width="60" height="60" className="mx-auto mb-8" />

              <p className="mb-4 text-2xl font-bold text-white">
                You must accept the Terms of Service to play HYTOPIA.
              </p>

              <p className="text-lg font-medium text-white/60">
                You are now logged out. Please log in again to continue the
                onboarding process and confirm your date of birth.
              </p>
            </div>

            <div className="bg-[#3d3d3d] p-8">
              <button
                onClick={async () => {
                  try {
                    await logout();
                    setIsOpen(false);
                  } catch (error) {
                    console.error(error);
                    toast.error("Something went wrong. Please try again.");
                  }
                }}
                className="w-full rounded-xl border border-white bg-white px-10 py-4 text-2xl font-bold text-[#151515] shadow-md transition hover:border-white/80 hover:bg-white/80 active:translate-y-1 active:shadow-none disabled:cursor-not-allowed disabled:opacity-50"
              >
                Done
              </button>
            </div>
          </div>
        );

      case "letsgo":
        return (
          <div className="mx-auto w-full max-w-4xl overflow-hidden rounded-2xl bg-[#1F1F1E] shadow-xl [view-transition-name:login-modal]">
            <div className="pointer-events-none fixed inset-0 flex select-none items-center justify-center">
              <ConfettiExplosion
                force={1.5}
                zIndex={50}
                duration={3000}
                particleCount={200}
                colors={["#FFA607", "#DAFF34", "#99FFE3", "#E7B181", "#F66AFF"]}
                width={
                  typeof window !== "undefined" ? window.innerWidth : undefined
                }
              />
            </div>

            <div className="relative flex flex-col justify-center bg-[#292828]">
              <h1 className="absolute left-1/2 top-14 z-20 -translate-x-1/2 text-center text-3xl font-bold text-white">
                Welcome to
              </h1>
              <div className="relative">
                <img
                  width="614"
                  height="352"
                  loading="lazy"
                  decoding="async"
                  alt="Background"
                  src="/img/onboardingFlow.png"
                  className="w-full object-contain"
                />
                <div className="absolute bottom-0 left-0 right-0 h-64 bg-gradient-to-t from-[#292828] to-transparent"></div>
              </div>
              <div className="relative z-10 px-8 pb-8">
                <h2 className="text-xl font-bold text-white">
                  The ultimate gaming platform, with new games releasing daily.
                </h2>
                <p className="text-xl/snug font-medium text-white/60">
                  Have a look around and start playing, trading, and
                  customizing!
                </p>
                <ul className="mt-4 list-disc pl-6 text-xl leading-relaxed text-white [&>li]:text-white">
                  <li>
                    <strong className="text-white">Play</strong>: Find and play
                    game experiences!
                  </li>
                  <li>
                    <strong className="text-white">Marketplace</strong>: Coming
                    soon!
                  </li>
                  <li>
                    <strong className="text-white">Locker</strong>: Coming soon!
                  </li>
                </ul>
              </div>
            </div>
            <div className="bg-[#3d3d3d] p-8">
              <button
                type="button"
                onClick={async () => {
                  try {
                    await logout();
                    setIsOpen(false);
                  } catch (error) {
                    console.error(error);
                    toast.error("Something went wrong. Please try again.");
                  }
                }}
                className="w-full rounded-xl border border-white bg-white px-10 py-4 text-2xl font-bold text-[#151515] shadow-md transition hover:border-white/80 hover:bg-white/80 active:translate-y-1 active:shadow-none disabled:cursor-not-allowed disabled:opacity-50"
              >
                Let&apos;s go!
              </button>
            </div>
          </div>
        );
    }
  };

  useEffect(() => {
    if (state.status === "awaiting-code-input") {
      startViewTransition(() => setStep("otp"));
    } else if (state.status === "done") {
      handlePreRegistrationUsername();
    }
  }, [state.status]);

  useEffect(() => {
    if (
      (rootLoaderData?.toast?.type === "info" &&
        rootLoaderData?.toast?.message === "username") ||
      (rootLoaderData?.toast?.type === "info" &&
        rootLoaderData?.toast?.message === "username_and_email")
    ) {
      if (rootLoaderData?.toast?.message === "username_and_email") {
        // linkEmail();
        setIsOpen(true);
        startViewTransition(() => setStep("email"));
      } else {
        setIsOpen(true);
        handlePreRegistrationUsername();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootLoaderData?.toast]);

  useEffect(() => {
    const signInFetcher = fetchers.find(
      (fetcher) => fetcher.key === "signInFetcher",
    );

    if (
      signInFetcher?.state === "submitting" ||
      (signInFetcher?.state === "loading" && signInFetcher?.data?.signIn)
    ) {
      setIsOpen(true);
      startViewTransition(() => setStep("loading"));
    } else if (
      signInFetcher?.state === "loading" &&
      !signInFetcher?.data?.signIn
    ) {
      setIsOpen(false);
    }
  }, [fetchers]);

  useEffect(() => {
    validateUsername(debouncedUsername);
  }, [debouncedUsername, validateUsername]);

  useEffect(() => {
    if (step === "username" || step === "email" || step === "otp") {
      setTimeout(() => {
        if (step === "username") {
          usernameInputRef.current?.focus();
        } else if (step === "email") {
          emailInputRef.current?.focus();
        }
      }, 100);
    }
  }, [step]);

  return (
    <Dialog open={isOpen} onClose={() => false} className="relative z-50">
      <DialogBackdrop
        transition
        className="fixed inset-0 bg-black/25 duration-300 ease-out data-[closed]:opacity-0 data-[closed]:duration-100 data-[closed]:ease-in"
      />
      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4">
          <DialogPanel
            transition
            className="w-full transform overflow-hidden duration-300 ease-out data-[closed]:scale-95 data-[closed]:opacity-0 data-[closed]:duration-100 data-[closed]:ease-in"
          >
            {renderContent()}
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
};

export default LoginModal;
