import {
  Dialog,
  DialogPanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  MenuSection,
  MenuSeparator,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { type MessageDto } from "@hytopia.com/lib/dist/social/chat/retrieveMessages";
import { Await, useRouteLoaderData } from "@remix-run/react";
import clsx from "clsx";
import { formatRelative } from "date-fns";
import { Fragment, Suspense, useEffect, useRef, useState } from "react";
import { flushSync } from "react-dom";
import toast from "react-hot-toast/headless";

import ReportMessageModal from "~/components/friends/ReportMessageModal";
import {
  ChevronDownIcon,
  FlagIcon,
  PlusIcon,
} from "~/components/ui/icons/icon";
import { useFriendsSidebar } from "~/providers/FriendsContext";
import { type loader as rootLoader } from "~/root";
import { hytopiaClient } from "~/utils/hytopiaClient";
import { startViewTransition } from "~/utils/transitions.client";

interface ChatModalProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setIsAddingFriend: (isAddingFriend: boolean) => void;
}

export default function ChatModal({
  isOpen,
  setIsOpen,
  setIsAddingFriend,
}: ChatModalProps) {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const messagesListRef = useRef<HTMLDivElement>(null);
  const [messages, setMessages] = useState<MessageDto[]>([]);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [messageToReport, setMessageToReport] = useState<string>("");
  const [isFetchingMessages, setIsFetchingMessages] = useState(false);
  const rootLoaderData = useRouteLoaderData<typeof rootLoader>("root");
  const {
    joinLobby,
    blockFriend,
    unblockFriend,
    unfriendFriend,
    favoriteFriend,
    unfavoriteFriend,
    getChatMessages,
    clearChatMessages,
    unreadMessageCount,
    markMessagesAsRead,
    selectedChatFriend,
    setSelectedChatFriend,
  } = useFriendsSidebar();

  async function sendMessage(friendId: string, message: string) {
    if (!rootLoaderData?.currentUser) {
      return;
    }

    try {
      const sendMessageRequest = await hytopiaClient.social.chat.sendMessage(
        rootLoaderData.currentUser.accessToken,
        friendId,
        {
          message,
          uniquifier: window.crypto.randomUUID(),
        },
      );

      if (sendMessageRequest.error) {
        return toast.error(sendMessageRequest.error.message);
      }
    } catch (error) {
      toast.error("Failed to send message. Please try again later.");
    }
  }

  useEffect(() => {
    rootLoaderData?.friends?.then((friends) => {
      if (friends?.length) {
        setSelectedChatFriend(friends[0]);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rootLoaderData?.friends]);

  useEffect(() => {
    if (!isOpen || !selectedChatFriend) {
      return;
    }

    setIsFetchingMessages(true);

    getChatMessages(selectedChatFriend.id)
      .then((messages) => {
        if (messages) {
          flushSync(() => setMessages(messages));

          if (messagesListRef.current) {
            messagesListRef.current.scrollTop =
              messagesListRef.current.scrollHeight;
          }
        } else {
          setMessages([]);
        }

        markMessagesAsRead(selectedChatFriend.id);
      })
      .catch(() => setMessages([]))
      .finally(() => setIsFetchingMessages(false));
  }, [isOpen, selectedChatFriend, getChatMessages, markMessagesAsRead]);

  return (
    <>
      <Transition show={isOpen} afterLeave={() => setIsExpanded(false)}>
        <Dialog
          open={isOpen}
          className="relative z-50"
          onClose={() => setIsOpen(false)}
        >
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 bg-black/90 [view-transition-name:backdrop]"
              aria-hidden="true"
            />
          </TransitionChild>

          <div className="fixed inset-0 flex items-center justify-center p-8 [view-transition-name:modal]">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-100"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <DialogPanel className="grid h-[470px] max-h-[60vh] w-full max-w-4xl grid-cols-[auto,1fr] grid-rows-[100%] overflow-hidden rounded-3xl border border-white/10">
                <div
                  className={clsx("grid gap-5 bg-neutral-800 px-4 py-7", {
                    "grid-cols-[160px,auto] grid-rows-[100%]": isExpanded,
                  })}
                >
                  <ul
                    className={clsx("flex flex-col gap-5 pl-1", {
                      "-my-7 overflow-auto border-r border-white/10 py-7 pr-5":
                        isExpanded,
                    })}
                  >
                    <Suspense>
                      <Await resolve={rootLoaderData?.friends}>
                        {(friends) => {
                          return friends?.map((friend, index) => (
                            <li
                              key={friend.id}
                              className={clsx({
                                hidden: !isExpanded && index > 6,
                              })}
                            >
                              <button
                                type="button"
                                onClick={() => {
                                  setSelectedChatFriend(friend);
                                  inputRef.current?.focus();
                                }}
                                className="group flex w-full items-center gap-4 rounded-full transition focus-visible:shadow-none focus-visible:outline-0 focus-visible:ring-1 focus-visible:ring-white focus-visible:ring-offset-2 focus-visible:ring-offset-neutral-800"
                              >
                                <span
                                  className={clsx(
                                    "relative w-14 rounded-full border p-1 transition group-hover:border-white",
                                    {
                                      "border-white":
                                        selectedChatFriend?.id === friend.id,
                                      "border-white/20":
                                        selectedChatFriend?.id !== friend.id,
                                    },
                                  )}
                                >
                                  {!isFetchingMessages &&
                                  unreadMessageCount.get(friend.id) ? (
                                    <span className="absolute -right-1.5 -top-1.5 rounded-full border-3 border-neutral-800 bg-white px-1 text-xs text-black">
                                      {unreadMessageCount.get(friend.id)}
                                    </span>
                                  ) : null}

                                  {friend.friend.profilePictureURL ? (
                                    <img
                                      width="32"
                                      height="32"
                                      loading="lazy"
                                      decoding="async"
                                      alt="HYTOPIA Server Avatar"
                                      src={friend.friend.profilePictureURL}
                                      className="rounded-full bg-neutral-800"
                                    />
                                  ) : (
                                    <span className="flex aspect-square w-full items-center justify-center rounded-full bg-black text-sm font-bold">
                                      {friend.friend.username[0].toUpperCase()}
                                    </span>
                                  )}

                                  <span
                                    className={clsx(
                                      "absolute -bottom-1 -right-1 h-5 w-5 rounded-full border-3 border-neutral-800",
                                      {
                                        "bg-gray-400":
                                          !friend.friend.isConnected,
                                        "bg-[#93E560]":
                                          friend.friend.isConnected,
                                      },
                                    )}
                                  ></span>
                                </span>

                                {isExpanded ? (
                                  <span className="w-full text-left">
                                    <strong>{friend.friend.username}</strong>
                                    <span className="block text-xs text-white/60">
                                      {friend.friend.isConnected
                                        ? friend.friend.currentLobby
                                          ? `Playing ${friend.friend.currentLobby.game.name}`
                                          : "Online"
                                        : "Offline"}
                                    </span>
                                  </span>
                                ) : null}
                              </button>
                            </li>
                          ));
                        }}
                      </Await>
                    </Suspense>
                  </ul>

                  <div className="flex flex-col justify-end">
                    <button
                      type="button"
                      onClick={() =>
                        startViewTransition(() =>
                          setIsExpanded((prev) => !prev),
                        )
                      }
                      className="group mx-auto mb-4 flex h-11 w-11 items-center justify-center rounded-full border border-transparent bg-neutral-700 text-white/60 transition hover:border-white hover:text-white focus-visible:shadow-none focus-visible:outline-0 focus-visible:ring-1 focus-visible:ring-white focus-visible:ring-offset-2 focus-visible:ring-offset-neutral-800"
                    >
                      {isExpanded ? (
                        <ChevronDownIcon
                          width="10"
                          height="10"
                          className="rotate-90 transform stroke-[5]"
                        />
                      ) : (
                        <Suspense fallback="-">
                          <Await resolve={rootLoaderData?.friends}>
                            {(friends) => friends?.length}
                          </Await>
                        </Suspense>
                      )}
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setIsOpen(false);
                        setIsAddingFriend(true);
                      }}
                      className="group mx-auto mb-1 flex h-11 w-11 items-center justify-center rounded-full border border-transparent bg-neutral-700 text-white/60 transition hover:border-white hover:text-white focus-visible:shadow-none focus-visible:outline-0 focus-visible:ring-1 focus-visible:ring-white focus-visible:ring-offset-2 focus-visible:ring-offset-neutral-800"
                    >
                      <PlusIcon width="10" height="10" className="stroke-[5]" />
                    </button>
                  </div>
                </div>
                <div className="flex flex-col bg-black">
                  <header className="flex items-center justify-between border-b border-white/10 px-6 py-6 leading-tight shadow-md">
                    <div>
                      <p className="mb-1 text-xl font-bold">
                        {selectedChatFriend?.friend.username}
                      </p>
                      <p className="text-sm text-white/60">
                        {selectedChatFriend?.friend.currentLobby?.game.name
                          ? `Playing ${selectedChatFriend?.friend.currentLobby.game.name} ·`
                          : ""}{" "}
                        <span className="inline-flex items-center gap-2">
                          {selectedChatFriend?.friend.isConnected
                            ? "Online"
                            : "Offline"}
                          <span
                            className={clsx("h-2.5 w-2.5 rounded-full", {
                              "bg-white/60":
                                !selectedChatFriend?.friend.isConnected,
                              "bg-[#93E560]":
                                selectedChatFriend?.friend.isConnected,
                            })}
                          ></span>
                        </span>
                      </p>
                    </div>
                    {selectedChatFriend ? (
                      <Menu>
                        <MenuButton className="group cursor-pointer rounded-lg border border-white/10 px-4 py-3 text-white/60 transition hover:border-white/20 hover:text-white focus:shadow-none focus:outline-0 focus:ring-1 focus:ring-white focus:ring-offset-2 focus:ring-offset-black">
                          <svg
                            width="20"
                            height="5"
                            viewBox="0 0 20 5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-6"
                          >
                            <path
                              d="M2.38647 4.73864C1.72738 4.73864 1.16203 4.50852 0.690444 4.0483C0.224535 3.58239 -0.00557913 3.01705 0.000102683 2.35227C-0.00557913 1.70455 0.224535 1.15057 0.690444 0.690341C1.16203 0.230114 1.72738 0 2.38647 0C3.01147 0 3.5626 0.230114 4.03988 0.690341C4.52283 1.15057 4.76715 1.70455 4.77283 2.35227C4.76715 2.79545 4.65067 3.19886 4.4234 3.5625C4.20181 3.92045 3.91203 4.20739 3.55408 4.4233C3.19613 4.63352 2.80692 4.73864 2.38647 4.73864Z"
                              fill="currentColor"
                            ></path>
                            <path
                              d="M9.88647 4.73864C9.22738 4.73864 8.66203 4.50852 8.19044 4.0483C7.72453 3.58239 7.49442 3.01705 7.5001 2.35227C7.49442 1.70455 7.72453 1.15057 8.19044 0.690341C8.66203 0.230114 9.22738 0 9.88647 0C10.5115 0 11.0626 0.230114 11.5399 0.690341C12.0228 1.15057 12.2671 1.70455 12.2728 2.35227C12.2671 2.79545 12.1507 3.19886 11.9234 3.5625C11.7018 3.92045 11.412 4.20739 11.0541 4.4233C10.6961 4.63352 10.3069 4.73864 9.88647 4.73864Z"
                              fill="currentColor"
                            ></path>
                            <path
                              d="M17.3865 4.73864C16.7274 4.73864 16.162 4.50852 15.6904 4.0483C15.2245 3.58239 14.9944 3.01705 15.0001 2.35227C14.9944 1.70455 15.2245 1.15057 15.6904 0.690341C16.162 0.230114 16.7274 0 17.3865 0C18.0115 0 18.5626 0.230114 19.0399 0.690341C19.5228 1.15057 19.7671 1.70455 19.7728 2.35227C19.7671 2.79545 19.6507 3.19886 19.4234 3.5625C19.2018 3.92045 18.912 4.20739 18.5541 4.4233C18.1961 4.63352 17.8069 4.73864 17.3865 4.73864Z"
                              fill="currentColor"
                            ></path>
                          </svg>
                        </MenuButton>
                        <Transition
                          enter="transition ease-out duration-75"
                          enterFrom="opacity-0 scale-95"
                          enterTo="opacity-100 scale-100"
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100 scale-100"
                          leaveTo="opacity-0 scale-95"
                        >
                          <MenuItems
                            anchor="bottom end"
                            className="mt-2 flex w-36 origin-top-right flex-col overflow-hidden rounded-lg border border-white/10 bg-neutral-800 shadow-lg will-change-transform focus:outline-0"
                          >
                            <MenuSection className="flex flex-col">
                              {selectedChatFriend.friend.currentLobby?.id ? (
                                <MenuItem>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      if (
                                        !selectedChatFriend.friend.currentLobby
                                      ) {
                                        return;
                                      }

                                      joinLobby(
                                        selectedChatFriend.friend.currentLobby
                                          .id,
                                      );
                                    }}
                                    className="px-4 py-3 text-right font-bold text-[#93E560] transition hover:bg-white/5 hover:text-white [&[data-headlessui-state^=active]]:bg-white/5 [&[data-headlessui-state^=active]]:text-white"
                                  >
                                    Join
                                  </button>
                                </MenuItem>
                              ) : null}
                              <MenuItem>
                                <button
                                  type="button"
                                  onClick={() => {
                                    if (selectedChatFriend.friend.isBlocked) {
                                      unblockFriend(
                                        selectedChatFriend.friend.id,
                                      );
                                    } else {
                                      blockFriend(selectedChatFriend.friend.id);
                                    }
                                  }}
                                  className="px-4 py-3 text-right font-bold text-white/60 transition hover:bg-white/5 hover:text-white [&[data-headlessui-state^=active]]:bg-white/5 [&[data-headlessui-state^=active]]:text-white"
                                >
                                  {selectedChatFriend.friend.isBlocked
                                    ? "Unblock"
                                    : "Block"}
                                </button>
                              </MenuItem>
                              <MenuItem>
                                <button
                                  type="button"
                                  onClick={() =>
                                    unfriendFriend(selectedChatFriend.friend)
                                  }
                                  className="px-4 py-3 text-right font-bold text-white/60 transition hover:bg-white/5 hover:text-white [&[data-headlessui-state^=active]]:bg-white/5 [&[data-headlessui-state^=active]]:text-white"
                                >
                                  Unfriend
                                </button>
                              </MenuItem>
                              <MenuItem>
                                <button
                                  type="button"
                                  onClick={() => {
                                    if (selectedChatFriend.isFavorited) {
                                      unfavoriteFriend(selectedChatFriend.id);
                                    } else {
                                      favoriteFriend(selectedChatFriend.id);
                                    }
                                  }}
                                  className="px-4 py-3 text-right font-bold text-white/60 transition hover:bg-white/5 hover:text-white [&[data-headlessui-state^=active]]:bg-white/5 [&[data-headlessui-state^=active]]:text-white"
                                >
                                  {selectedChatFriend.isFavorited
                                    ? "Unfavorite"
                                    : "Favorite"}
                                </button>
                              </MenuItem>
                              <MenuItem>
                                <button
                                  type="button"
                                  onClick={() =>
                                    clearChatMessages(selectedChatFriend.id)
                                  }
                                  className="px-4 py-3 text-right font-bold text-white/60 transition hover:bg-white/5 hover:text-white [&[data-headlessui-state^=active]]:bg-white/5 [&[data-headlessui-state^=active]]:text-white"
                                >
                                  Clear Chat
                                </button>
                              </MenuItem>
                            </MenuSection>
                            <MenuSeparator className="h-px bg-white/10" />
                            <MenuSection className="flex flex-col">
                              <MenuItem>
                                <button
                                  type="button"
                                  onClick={() => setIsOpen(false)}
                                  className="px-4 py-3 text-right font-bold text-white/60 transition hover:bg-white/5 hover:text-white [&[data-headlessui-state^=active]]:bg-white/5 [&[data-headlessui-state^=active]]:text-white"
                                >
                                  Close
                                </button>
                              </MenuItem>
                            </MenuSection>
                          </MenuItems>
                        </Transition>
                      </Menu>
                    ) : null}
                  </header>
                  <div
                    ref={messagesListRef}
                    className="flex-1 overflow-auto px-6 py-5"
                  >
                    <p className="mb-5 leading-snug text-white/60">
                      This is the beginning of your messages with{" "}
                      <strong>{selectedChatFriend?.friend.username}</strong>.
                      Chat messages are only stored for 2 weeks.
                    </p>

                    {messages.length ? (
                      <ol className="flex flex-col gap-2 text-lg">
                        {messages.map((message) => (
                          <li
                            key={message.id}
                            className={clsx("group relative pr-6", {
                              "text-white":
                                message.senderId !==
                                selectedChatFriend?.friend.id,
                              "text-[#54CCFF]":
                                message.senderId ===
                                selectedChatFriend?.friend.id,
                            })}
                          >
                            [
                            <span className="inline-block first-letter:uppercase">
                              {formatRelative(message.timestamp, new Date())}
                            </span>
                            ]{" "}
                            <strong>
                              {message.senderId ===
                              selectedChatFriend?.friend.id
                                ? selectedChatFriend.friend.username
                                : rootLoaderData?.currentUser?.username}
                            </strong>{" "}
                            {message.message}
                            {message.senderId ===
                            selectedChatFriend?.friend.id ? (
                              <button
                                type="button"
                                onClick={() => {
                                  setIsReportModalOpen(true);
                                  setMessageToReport(message.id);
                                }}
                                className="absolute right-0 top-1.5 text-white/60 opacity-0 transition hover:text-white group-hover:opacity-100"
                              >
                                <FlagIcon width="10" height="12" />
                              </button>
                            ) : null}
                          </li>
                        ))}
                      </ol>
                    ) : null}
                  </div>
                  <div className="p-6">
                    <div className="grid after:invisible after:col-start-1 after:row-start-1 after:whitespace-pre-wrap after:px-5 after:py-3.5 after:text-lg after:[content:attr(data-replicated-value)_'']">
                      <textarea
                        rows={1}
                        ref={inputRef}
                        data-autofocus
                        onInput={(e) => {
                          if (
                            e.currentTarget.parentNode instanceof HTMLElement
                          ) {
                            e.currentTarget.parentNode.dataset.replicatedValue =
                              e.currentTarget.value;
                          }
                        }}
                        onKeyDown={async (e) => {
                          if (
                            e.key === "Enter" &&
                            !e.shiftKey &&
                            selectedChatFriend
                          ) {
                            e.preventDefault();

                            const message = e.currentTarget.value.trim();
                            e.currentTarget.value = "";

                            await sendMessage(selectedChatFriend.id, message);
                          }
                        }}
                        disabled={
                          selectedChatFriend?.friend.isBlocked ||
                          selectedChatFriend?.friend.isBlockedByOther
                        }
                        placeholder={
                          selectedChatFriend?.friend.isBlocked
                            ? "You have blocked this user. Unblock them to send messages."
                            : selectedChatFriend?.friend.isBlockedByOther
                              ? "This user has blocked you."
                              : "Say something then hit enter..."
                        }
                        className="col-start-1 row-start-1 w-full resize-none overflow-hidden rounded-xl border border-transparent bg-neutral-800 px-5 py-3.5 text-lg shadow-md outline outline-offset-1 outline-transparent transition placeholder:text-neutral-500 focus:border-white focus:shadow-none disabled:cursor-not-allowed disabled:opacity-60"
                      />
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>

      <ReportMessageModal
        isOpen={isReportModalOpen}
        messageId={messageToReport}
        setIsOpen={setIsReportModalOpen}
      />
    </>
  );
}
