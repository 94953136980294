import { Await, useFetcher, useRouteLoaderData } from "@remix-run/react";
import clsx from "clsx";
import { formatRelative } from "date-fns";
import { Suspense, useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast/headless";
import { ClientOnly } from "remix-utils/client-only";

import FriendsListSection from "~/components/FriendsListSection";
import Modal from "~/components/Modal";
import UserBadge from "~/components/UserBadge";
import ChatModal from "~/components/friends/ChatModal";
import {
  MessageIcon,
  PersonPlusIcon,
  SearchIcon,
} from "~/components/ui/icons/icon";
import { useFriendsSidebar } from "~/providers/FriendsContext";
import { type loader as rootLoader } from "~/root";
import { type action as acceptFriendAction } from "~/routes/friends+/accept";
import { type action as declineFriendAction } from "~/routes/friends+/decline";
import { type action as sendFriendAction } from "~/routes/friends+/send";

export default function FriendsList() {
  const [friendFilter, setFriendFilter] = useState("");
  const sendFormRef = useRef<HTMLFormElement | null>(null);
  const sendFetcher = useFetcher<typeof sendFriendAction>();
  const acceptFetcher = useFetcher<typeof acceptFriendAction>();
  const declineFetcher = useFetcher<typeof declineFriendAction>();
  const rootLoaderData = useRouteLoaderData<typeof rootLoader>("root");
  const {
    isVisible,
    isChatOpen,
    setIsChatOpen,
    unreadMessageCount,
    isAddingFriend,
    setIsAddingFriend,
  } = useFriendsSidebar();

  const hasNewMessages = useMemo(() => {
    return Array.from(unreadMessageCount.values()).some((count) => count > 0);
  }, [unreadMessageCount]);

  useEffect(() => {
    if (sendFetcher.data && !sendFetcher.data.error) {
      sendFormRef.current?.reset();
    }
  }, [sendFetcher.data]);

  useEffect(() => {
    const error =
      sendFetcher.data?.error ||
      declineFetcher.data?.error ||
      acceptFetcher.data?.error;

    if (error) {
      toast.error(error);
    }

    return () => {
      toast.remove();
    };
  }, [sendFetcher.data, declineFetcher.data, acceptFetcher.data]);

  useEffect(() => {
    if (!isVisible) {
      setFriendFilter("");
    }
  }, [isVisible]);

  return (
    <>
      <div className={clsx("friends-sidebar", { hidden: !isVisible })}>
        <div className="pointer-events-none fixed inset-0" />
        <div className="h-full">
          <div
            className={clsx(
              "fixed bottom-0 right-0 top-0 isolate z-20 flex h-full w-[min(320px,19.5%)] flex-col border-l border-[#F1F1F1]/10 bg-[#0d0d0d]/75 focus:outline-0",
              {
                block: isVisible,
                hidden: !isVisible,
              },
            )}
          >
            <div className="flex h-[91px] shrink-0 items-center justify-between border-b border-[#F1F1F1]/10 px-8">
              <div className="flex gap-x-4">
                <button
                  type="button"
                  onClick={() => setIsAddingFriend(true)}
                  className={clsx(
                    "relative flex aspect-square items-center justify-center rounded-xl border border-transparent bg-[#F1F1F1]/10 px-4 py-4 transition hover:border-white focus-visible:outline-0 focus-visible:ring-1 focus-visible:ring-white",
                  )}
                >
                  <PersonPlusIcon width="22" height="20" />

                  <Suspense>
                    <Await resolve={rootLoaderData?.friendRequests}>
                      {(requests) => {
                        if (!requests?.incoming.length) {
                          return null;
                        }

                        return (
                          <div className="absolute right-2 top-2 h-[7.5px] w-[7.5px] rounded-full bg-[#F1F1F1]"></div>
                        );
                      }}
                    </Await>
                  </Suspense>
                </button>
                <Suspense>
                  <Await resolve={rootLoaderData?.friends}>
                    {(friends) => {
                      if (!friends?.length) {
                        return null;
                      }

                      return (
                        <button
                          type="button"
                          onClick={() => setIsChatOpen(true)}
                          className={clsx(
                            "relative flex aspect-square items-center justify-center rounded-xl border border-transparent bg-[#F1F1F1]/10 px-4 py-4 transition hover:border-white focus-visible:outline-0 focus-visible:ring-1 focus-visible:ring-white",
                          )}
                        >
                          <MessageIcon
                            width="22"
                            height="24"
                            className="stroke-2 text-white/60"
                          />

                          {hasNewMessages ? (
                            <div className="absolute right-2 top-2 h-[7.5px] w-[7.5px] rounded-full bg-[#F1F1F1]"></div>
                          ) : null}
                        </button>
                      );
                    }}
                  </Await>
                </Suspense>
              </div>
            </div>

            <div className="flex flex-1 flex-col overflow-y-auto py-6">
              <Suspense>
                <Await resolve={rootLoaderData?.friendRequests}>
                  {(requests) => {
                    if (!requests?.incoming.length) {
                      return null;
                    }

                    return (
                      <div className="mb-6 border-b border-white/10 px-8 pb-6">
                        <div className="mb-3 flex w-full items-center justify-between">
                          <div className="flex items-center gap-x-1.5 text-[12px] opacity-50">
                            <p className="font-extrabold">Friend Requests</p>
                            <p className="font-normal">
                              ({requests.incoming.length})
                            </p>
                          </div>
                        </div>

                        <div className="mb-2 flex">
                          {requests.incoming.map((request) => (
                            <button
                              type="button"
                              key={request.id}
                              onClick={() => setIsAddingFriend(true)}
                              className="stacked-avatar relative h-[32px] w-[32px] cursor-pointer rounded-full opacity-90 transition hover:scale-105 hover:opacity-100"
                            >
                              {request.sender.profilePictureURL ? (
                                <img
                                  width="32"
                                  height="32"
                                  loading="lazy"
                                  alt={request.sender.username}
                                  className="h-full w-full rounded-full"
                                  src={request.sender.profilePictureURL}
                                />
                              ) : (
                                <span className="flex aspect-square items-center justify-center rounded-full bg-black font-bold">
                                  {request.sender.username[0].toUpperCase()}
                                </span>
                              )}
                            </button>
                          ))}
                        </div>
                      </div>
                    );
                  }}
                </Await>
              </Suspense>

              <Suspense>
                <Await resolve={rootLoaderData?.friends}>
                  {(friends) => {
                    if (!friends?.length) {
                      return (
                        <div className="my-auto px-8 text-center text-white/60">
                          <h3 className="mb-4 text-xl font-bold">
                            No friends yet.
                          </h3>
                          <p className="mb-6 text-lg">
                            Find friends while playing games or if you know
                            someone&apos;s name, try adding them directly.
                          </p>
                          <button
                            type="button"
                            onClick={() => setIsAddingFriend(true)}
                            className="inline-flex rounded-xl border border-transparent bg-[#F1F1F1]/10 px-6 py-3 text-lg font-bold transition hover:border-white focus-visible:outline-0 focus-visible:ring-1 focus-visible:ring-white"
                          >
                            Add New Friend
                          </button>
                        </div>
                      );
                    }

                    const favoriteFriends = friends
                      .filter((friend) => friend.isFavorited)
                      .filter(({ friend }) => {
                        return friend.username
                          .toLowerCase()
                          .includes(friendFilter);
                      });

                    const onlineFriends = friends
                      .filter(
                        (friend) =>
                          !friend.isFavorited && friend.friend.isConnected,
                      )
                      .filter(({ friend }) => {
                        return friend.username
                          .toLowerCase()
                          .includes(friendFilter);
                      });

                    const offlineFriends = friends
                      .filter(
                        (friend) =>
                          !friend.isFavorited && !friend.friend.isConnected,
                      )
                      .filter(({ friend }) => {
                        return friend.username
                          .toLowerCase()
                          .includes(friendFilter);
                      });

                    return (
                      <ClientOnly>
                        {() => (
                          <div className="flex flex-col gap-8">
                            <div className="px-8">
                              <div className="relative text-white/60 focus-within:text-white">
                                <SearchIcon
                                  width="16"
                                  height="16"
                                  className="absolute left-4 top-4 transition"
                                />
                                <input
                                  type="text"
                                  value={friendFilter}
                                  placeholder="Search friends"
                                  onChange={(e) =>
                                    setFriendFilter(e.target.value)
                                  }
                                  className="w-full rounded-xl border border-transparent bg-white/10 py-3 pl-14 pr-4 text-xl transition placeholder:text-white/60 focus:outline-none focus:ring-1 focus:ring-white"
                                />
                              </div>
                            </div>

                            {!favoriteFriends.length &&
                            !onlineFriends.length &&
                            !offlineFriends.length ? (
                              <div className="px-8 text-white/60">
                                <h3 className="mb-4 text-xl font-bold">
                                  No friends found.
                                </h3>
                                <p className="text-lg">
                                  Try searching for a friend&apos;s username or
                                  add a new friend.
                                </p>
                              </div>
                            ) : null}

                            {favoriteFriends.length > 0 ? (
                              <FriendsListSection
                                text="Favorites"
                                friends={favoriteFriends}
                              />
                            ) : null}

                            {onlineFriends.length > 0 ? (
                              <FriendsListSection
                                text="Online"
                                friends={onlineFriends}
                              />
                            ) : null}

                            {offlineFriends.length > 0 ? (
                              <FriendsListSection
                                text="Offline"
                                friends={offlineFriends}
                              />
                            ) : null}
                          </div>
                        )}
                      </ClientOnly>
                    );
                  }}
                </Await>
              </Suspense>
            </div>
          </div>
        </div>
      </div>

      <Modal
        title="Add Friends"
        isOpen={isAddingFriend}
        setIsOpen={setIsAddingFriend}
      >
        <div className="bg-black p-8">
          <sendFetcher.Form
            method="POST"
            ref={sendFormRef}
            action="/friends/send"
          >
            <label className="mb-4 block">
              <span className="mb-3 block text-xl font-bold text-white/50">
                Type in your friend&apos;s username
              </span>
              <div className="relative">
                <input
                  required
                  type="text"
                  data-autofocus
                  name="username"
                  autoComplete="off"
                  disabled={sendFetcher.state !== "idle"}
                  className="peer w-full rounded-2xl border border-transparent bg-white/10 py-5 pl-6 pr-64 text-[16px] leading-tight shadow-md outline outline-offset-1 outline-transparent transition-all focus:border-white focus:shadow-none disabled:opacity-60"
                />
                <button
                  type="submit"
                  disabled={sendFetcher.state !== "idle"}
                  className="absolute right-[3px] top-1 rounded-xl border border-white bg-white px-5 py-4 text-lg font-bold text-black shadow-md transition hover:border-white/80 hover:bg-white/80 active:translate-y-1 active:shadow-none disabled:opacity-60"
                >
                  {sendFetcher.state !== "idle"
                    ? "Sending..."
                    : "Send Friend Request"}
                </button>
              </div>
            </label>
          </sendFetcher.Form>

          <Suspense>
            <Await resolve={rootLoaderData?.friendRequests}>
              {(requests) => {
                if (!requests?.incoming.length && !requests?.outgoing.length) {
                  return null;
                }

                return (
                  <>
                    <p className="mb-3 mt-8 block text-xl text-white/50">
                      <span className="font-bold">Requests</span> (
                      {requests.incoming.length + requests.outgoing.length})
                    </p>

                    <ul className="flex flex-col">
                      {requests.incoming.map((request) => {
                        return (
                          <li key={request.id} className="group relative">
                            <div className="group -mx-8 flex items-center border-b border-white/10 px-8 transition hover:bg-white/10 group-last:border-0">
                              <div className="flex-1">
                                <UserBadge
                                  status={
                                    request.sender.isConnected
                                      ? "Online"
                                      : "Offline"
                                  }
                                  className="px-0 py-4"
                                  name={request.sender.username}
                                  src={request.sender.profilePictureURL}
                                />
                              </div>
                              <div className="flex gap-4">
                                <declineFetcher.Form
                                  method="POST"
                                  action="/friends/decline"
                                >
                                  <button
                                    type="submit"
                                    className="rounded-xl px-5 py-2 text-lg font-bold text-white/60 transition hover:border-white hover:text-white active:scale-95"
                                  >
                                    Decline
                                  </button>
                                  <input
                                    type="hidden"
                                    name="friendRequestId"
                                    value={request.id}
                                  />
                                </declineFetcher.Form>

                                <acceptFetcher.Form
                                  method="POST"
                                  action="/friends/accept"
                                >
                                  <button
                                    type="submit"
                                    className="rounded-xl border border-transparent bg-white/10 px-5 py-2 text-lg font-bold text-white/60 transition will-change-transform hover:border-white hover:text-white active:scale-95"
                                  >
                                    Accept
                                  </button>
                                  <input
                                    type="hidden"
                                    name="friendRequestId"
                                    value={request.id}
                                  />
                                </acceptFetcher.Form>
                              </div>
                            </div>
                          </li>
                        );
                      })}

                      {requests.outgoing.map((request) => {
                        return (
                          <li key={request.id} className="group relative">
                            <div className="group -mx-8 flex items-center border-b border-white/10 px-8 transition hover:bg-white/10 group-last:border-0">
                              <div className="flex-1">
                                <UserBadge
                                  status={
                                    request.target.isConnected
                                      ? "Online"
                                      : "Offline"
                                  }
                                  className="px-0 py-4"
                                  name={request.target.username}
                                  src={request.target.profilePictureURL}
                                />
                              </div>

                              <p className="text-sm text-white/60">
                                Sent{" "}
                                {formatRelative(
                                  new Date(request.sentAt),
                                  new Date(),
                                )}
                              </p>
                            </div>
                          </li>
                        );
                      })}
                    </ul>

                    {requests.incoming.length > 1 ? (
                      <div className="flex justify-between gap-4 pt-6">
                        <button
                          type="button"
                          className="rounded-xl py-2 pr-5 text-lg font-bold text-white/60 transition hover:border-white hover:text-white active:scale-95"
                        >
                          Deny All
                        </button>

                        <button
                          type="button"
                          className="rounded-xl border border-transparent bg-white/10 px-12 py-3 text-lg font-bold text-white/60 transition will-change-transform hover:border-white hover:text-white active:scale-95"
                        >
                          Approve All
                        </button>
                      </div>
                    ) : null}
                  </>
                );
              }}
            </Await>
          </Suspense>
        </div>
      </Modal>

      <ChatModal
        isOpen={isChatOpen}
        setIsOpen={setIsChatOpen}
        setIsAddingFriend={setIsAddingFriend}
      />
    </>
  );
}
