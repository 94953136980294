declare global {
  interface Window {
    gtag: (
      option: string,
      gaTrackingId: string,
      options: Record<string, unknown>,
    ) => void;
  }
}

/**
 * Track pageview in Google Analytics
 *
 * @param url Page URL
 * @param trackingId Google Analytics tracking ID
 */
export const pageview = (url: string, trackingId: string) => {
  if (!window.gtag) {
    return;
  }

  window.gtag("config", trackingId, {
    page_path: url,
  });
};

/**
 * Track event in Google Analytics
 *
 * @param action Event action
 * @param category Event category
 * @param label Event label
 * @param value Event value
 */
export const event = ({
  action,
  category,
  label,
  value,
  send_to,
}: Record<string, string>) => {
  if (!window.gtag) {
    return;
  }

  window.gtag("event", action, {
    event_category: category,
    event_label: label,
    value,
    send_to,
  });
};
